<ng-container *ngIf="status == 'success'">
    <div class="status-container">
        <img src="/assets/images/check.png" alt="">
        <p>Your stripe payment has been completed successfully.</p>
    </div>
</ng-container>
<ng-container *ngIf="status == 'failed'">
    <div class="status-container">
        <img src="/assets/images/uncheck.png" alt="">
        <p>Your stripe payment has been failed.</p>
    </div>
</ng-container>