import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-stripe-payment-status',
    templateUrl: './stripe-payment-status.component.html',
    styleUrls: ['./stripe-payment-status.component.css']
})
export class StripePaymentStatusComponent implements OnInit {
    status: any

    constructor(
        private route: ActivatedRoute
    ) {
        this.status = this.route.snapshot.params.status
    }

    ngOnInit() {
    }

}
